import Vue from "vue";
import Vuex from "vuex";
import root from "./modules/root";
import interactiveProjects from "./modules/interactiveProjects";
import gallery from "./modules/gallery";

// create connection ------------ //
Vue.use(Vuex);

// collection of all modules --------------//
// allowing access from vue
export default new Vuex.Store({
  modules: {
    root,
    interactiveProjects,
    gallery
  },
});
