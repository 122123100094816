<template>
  <div id="side-menu" :style="logoStyles">
    <router-link :to="{ name: 'home' }">
      <div class="logo">
        <img src="@/assets/icons/sad_tech_logo.svg"
             alt="sad_tech logo, link to home"
             draggable="false"
        />
      </div>
    </router-link>
  </div>
</template>

<script>
import { map } from '@/functions/utility.js'

export default {
  name: 'SideMenu',
  data() {
    return {
      scrollHeight: window.innerHeight,
      scrollPosition: 0,
    }
  },
  computed: {
    logoStyles() {
      return {
        "top": `${this.scrollPosition}px`
      }
    },
    currentProject() {
      return this.$store.getters.getCurrentProject
    }
  },
  watch:{
    $route (to, from){
      if(to !== from) {
        this.setScrollHeight()
        this.getScrollPosition()
      }
    },
    currentProject() {
      setTimeout(() => {
        this.setScrollHeight()
      },100)
    }
  },
  methods: {
    setScrollHeight() {
      let pageHeight = window.innerHeight
      let scrollHeight = document.body.scrollHeight
      this.scrollHeight = scrollHeight > pageHeight ? scrollHeight : pageHeight
    },
    getScrollPosition() {
      let scrollPos = window.pageYOffset
      let pageHeight = window.innerHeight

      let el = document.querySelector('#side-menu')
      let elHeight = el ? el.getBoundingClientRect().height : 0
      let scrollMax = this.scrollHeight - pageHeight
      let paddingTop = Math.floor(map(scrollPos, 0, (scrollMax ? scrollMax : 1), 0, (pageHeight - elHeight)))

      //get position compared to whole height
      //map 0 -> (100vh - img.height)   0 -> (scrollHeight - height)
      this.scrollPosition = paddingTop
    },
    resetPosOnPageResize() {
      this.setScrollHeight()
      this.getScrollPosition()
    }
  },
  mounted() {
    setTimeout(() => {
      this.setScrollHeight()
      //also run on window resized + when switching page
    }, 200)
    window.addEventListener('scroll', this.getScrollPosition)
    window.addEventListener('resize', this.resetPosOnPageResize)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.getScrollPosition)
    window.removeEventListener('resize', this.resetPosOnPageResize)
  }
}
</script>

<style>
#side-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
  padding: 0.5rem;
}
.logo {
  width: var(--side-menu-width);
}
.logo {
  color: transparent;
}
.logo > * {
  color: white;
}

table tr:nth-child(1) td:nth-child(1) {
  color: red;
}
</style>
