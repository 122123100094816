import routes from "./routes";
import VueRouter from "vue-router";

const router = new VueRouter({
    routes,
    // mode: "history",
    scrollBehavior () {
      return { x: 0, y: 0 }
    }
});


export default router;
