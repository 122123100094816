const state = {
  currentProject: '',
  projects: {
    typeDistortionFilters2: {
      background: 'textDistortion2.gif',
      title: 'Text Distortion Filters + Grainy Gradients with Shaders',
      desc: 'Fully customisable experimental typography with different types of wavy distortion filters, with additional effects and noise',
      instructions: 'Use options panel to customise motion, design, shader and effects settings',
      types: ['p5.js', 'shaders'],
      tags: ['type', 'distortion', 'customisable', 'effects'],
      url: "https://editor.p5js.org/sadbot.tech/embed/9gkSHZ7ia",
      editUrl: "https://editor.p5js.org/sadbot.tech/sketches/9gkSHZ7ia",
      // opLink: 'https://www.openprocessing.org/sketch/1050469/',
      // gitRepo: 'https://github.com/sadte-ch/text-distortion-filters-with-shaders.git',
    },
    graphicDistortionFilters2: {
      background: 'graphicDistortion2.gif',
      title: 'Graphic Distortion Filters + Shaping Functions with Shaders',
      desc: 'Mouse reactive wavy blob distortion and shaping functions',
      instructions: 'Move mouse to change amount of distortion and the shape',
      types: ['p5.js', 'shaders'],
      tags: ['shape', 'distortion', 'mouse'],
      url: "https://editor.p5js.org/sadbot.tech/embed/KRK-Yt5nG",
      editUrl: "https://editor.p5js.org/sadbot.tech/sketches/KRK-Yt5nG",
      // opLink: 'https://www.openprocessing.org/sketch/1049003',
      // gitRepo: 'https://github.com/sadte-ch/graphic-distortion-filters-with-shaders.git',
    },
    imageDistortionFilters2: {
      background: 'imageDistortion2.gif',
      title: 'Fisheye Image Distortion Filters + Grainy Gradients with Shaders',
      desc: 'Fully customisable experimental image distortion with different types of wavy distortion filters, with additional effects and noise',
      instructions: 'Use options panel to upload images, customise motion, design, shader and effects settings',
      types: ['p5.js', 'shaders'],
      tags: ['image', 'distortion', 'customisable', 'effects'],
      url: "https://editor.p5js.org/sadbot.tech/embed/SvGT1eo4G",
      editUrl: "https://editor.p5js.org/sadbot.tech/sketches/SvGT1eo4G",
      // opLink: 'https://www.openprocessing.org/sketch/1048997',
      // gitRepo: 'https://github.com/sadte-ch/image-distortion-filters-with-shaders.git',
    },
    typeDistortionFilters: {
      background: 'textDistortion.gif',
      title: 'Text Distortion Filters with Shaders',
      desc: 'Fully customisable experimental typography with different types of wavy distortion filters',
      instructions: 'Use options panel to customise motion, design and shader settings',
      types: ['p5.js', 'shaders'],
      tags: ['type', 'distortion', 'customisable'],
      url: "https://editor.p5js.org/sadbot.tech/embed/9_K_Z8S8T",
      editUrl: "https://editor.p5js.org/sadbot.tech/sketches/9_K_Z8S8T",
      opLink: 'https://www.openprocessing.org/sketch/1050469/',
      gitRepo: 'https://github.com/sadte-ch/text-distortion-filters-with-shaders.git',
    },
    graphicDistortionFilters: {
      background: 'graphicDistortion.gif',
      title: 'Graphic Distortion Filters with Shaders',
      desc: 'Mouse reactive wavy blob distortion',
      instructions: 'Move mouse to change amount of distortion',
      types: ['p5.js', 'shaders'],
      tags: ['shape', 'distortion', 'mouse'],
      url: "https://editor.p5js.org/sadbot.tech/embed/mnskGaMEI",
      editUrl: "https://editor.p5js.org/sadbot.tech/sketches/mnskGaMEI",
      opLink: 'https://www.openprocessing.org/sketch/1049003',
      gitRepo: 'https://github.com/sadte-ch/graphic-distortion-filters-with-shaders.git',
    },
    imageDistortionFilters: {
      background: 'imageDistortion.gif',
      title: 'Image Distortion Filters with Shaders',
      desc: 'Mouse reactive wavy image distortion',
      instructions: 'Move mouse to change amount of distortion',
      types: ['p5.js', 'shaders'],
      tags: ['image', 'distortion', 'mouse'],
      url: "https://editor.p5js.org/sadbot.tech/embed/Qynekjy7u",
      editUrl: "https://editor.p5js.org/sadbot.tech/sketches/Qynekjy7u",
      opLink: 'https://www.openprocessing.org/sketch/1048997',
      gitRepo: 'https://github.com/sadte-ch/image-distortion-filters-with-shaders.git',
    }
  }
};

const getters = {
  getCurrentProject: (state) => state.currentProject,
  getCurrentProjectData: (state) => state.projects[state.currentProject],
  getProjects: (state) => state.projects,
};

const mutations = {
  setCurrentProject(state, val) {
    state.currentProject = val;
  },
};

const actions = {
  async openProject({commit},val) {
    commit('setCurrentProject', val)
    //scroll to top of page
    window.scrollTo(0,0);
  },
  async closeProjectPreview({commit}) {
    commit('setCurrentProject', '')
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
