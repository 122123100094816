<template>
  <div id="app">
    <side-menu />
    <section id="content">
      <router-view />
    </section>
  </div>
</template>

<script>
import SideMenu from '@/components/menu/SideMenu.vue'

export default {
  name: 'App',
  components: {
    SideMenu
  },
  mounted() {
    this.$store.dispatch('loadApp')
  }
}
</script>

<style>
/* fat text */
@font-face {
  font-family: Shapiro-95SuperExtd;
  src: url("./assets/fonts/Shapiro-95SuperExtd.otf") format("truetype");
}
/* featured text */
@font-face {
  font-family: Shapiro-65LightHeavyExtd;
  src: url("./assets/fonts/Shapiro-65LightHeavyExtd.otf") format("truetype");
}
/* large titles */
@font-face {
  font-family: Shapiro-55MiddleExtd;
  src: url("./assets/fonts/Shapiro-55MiddleExtd.otf") format("truetype");
}
/* sub titles */
/* @font-face {
  font-family: Shapiro-65LightHeavyExtd;
  src: url("./assets/fonts/Shapiro-65LightHeavyExtd.otf") format("truetype");
} */
/* highlighted text */
@font-face {
  font-family: Shapiro-55MiddleWide;
  src: url("./assets/fonts/Shapiro-55MiddleWide.otf") format("truetype");
}
/* body text */
@font-face {
  font-family: Shapiro-35FeatherText;
  src: url("./assets/fonts/Shapiro-35FeatherText.otf") format("truetype");
}
#app {
  font-family: Shapiro-35FeatherText, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#content {
  display: grid;
}
h1 {
  font-family: Shapiro-65LightHeavyExtd, sans-serif;
  font-weight: normal;
}
h2,h3 {
  font-family: Shapiro-55MiddleExtd, sans-serif;
  font-weight: normal;
}
h4,h5,h6 {
  font-family: Shapiro-55MiddleWide, sans-serif;
  font-weight: normal;
}
p,span,a {
  font-family: Shapiro-35FeatherText, sans-serif;
  letter-spacing: 0.5px;
  font-size: 110%;
}


iframe {
  margin-top: calc(12px * 3.5 * -1) !important;
}

#defaultCanvas0 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 90vh;
  width: 100%;
}

.dg.ac {
  z-index:1000 !important;
  position: absolute !important;
}
.dg.ac .button.save-as {
  display: none;
}
.dg.main {
  margin-right: 3rem !important;
  z-index:1000 !important;
}
.dg.main .title {
  font-size: 12px;
  font-weight: bold;
}
.dg.main li.cr {
  background: rgba(10,10,10,0.85);
}
.dg.main li.folder .title {
  opacity: 0.9;
}
</style>
