export default [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home "*/ "@/pages/Home.vue"),
    meta: {
      title: 'Sad Tech | Code and Stuff',
      metaTags: [
        {
          name: 'description',
          content: 'Generative tools for creatives and graphic designers to experiment with'
        },
        {
          property: 'og:description',
          content: 'Generative tools for creatives and graphic designers to experiment with'
        }
      ]
    }
  },
  {
    path: "/interactive-projects",
    name: "interactive-projects",
    component: () =>
      import(/* webpackChunkName: "lobby "*/ "@/pages/InteractiveProjects.vue"),
    meta: {
      title: 'Sad Tech | Interactive Projects',
      metaTags: [
        {
          name: 'description',
          content: 'A directory of all my interactive code and design experiments. Play, edit and create your own artwork.'
        },
        {
          property: 'og:description',
          content: 'A directory of all my interactive code and design experiments. Play, edit and create your own artwork.'
        }
      ]
    }
  },
  // {
  //   path: "/space/:roomId",
  //   name: "space",
  //   component: () =>
  //     import(/* webpackChunkName: "roomPage "*/ "@/pages/RoomPage.vue"),
  // },
  {
    path: "*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404Page "*/ "@/pages/404Page.vue"),
    meta: {
      title: 'Sad Tech | Not Found',
      metaTags: [
        {
          name: 'description',
          content: 'Page not found'
        },
        {
          property: 'og:description',
          content: 'Page not found'
        }
      ]
    }
  },
];
