const state = {
};

const getters = {

};

const mutations = {

};

const actions = {
  async loadApp({dispatch}) {
    dispatch('getInstaRssFeed')
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
