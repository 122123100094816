const state = {
  rssFeedUrl: "https://zapier.com/engine/rss/9373700/sad-tech",
  galleryPosts: [],
  currentLightboxPost: '',
};

const getters = {
  getRssFeedUrl: (state) => state.rssFeedUrl,
  getGalleryPosts: (state) => state.galleryPosts,
  getIfAlreadyInGallery: (state) => (link) => {
    let dupes = state.galleryPosts.filter((post) => post.link === link)
    return dupes.length
  },
  getPostsWithoutItem: (state) => (link) => {
    let postsWithoutItem = state.galleryPosts.filter((post) => post.link !== link)
    return postsWithoutItem
  },
  getCurrentLightboxPost: (state) => state.currentLightboxPost,
};

const mutations = {
  addGalleryPost(state, val) {
    state.galleryPosts.push(val)
  },
  setGalleryPosts(state, val) {
    state.galleryPosts = val
  },
  setCurrentLightboxPost(state, val) {
    state.currentLightboxPost = val;
  },
};

const actions = {
  async getInstaRssFeed({dispatch, getters}) {
    let feedUrl = getters.getRssFeedUrl
    fetch(feedUrl)
      .then(response => response.text())
      .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
      .then(data => {
        const items = data.querySelectorAll("item");
        // let postLinks = []
        items.forEach(el => {
          let post = {
            link: '',
            image: '',
            title: '',
            type: ''
          }
          post.image = el.getElementsByTagName('enclosure')[0].getAttribute('url')
          post.type = el.getElementsByTagName('enclosure')[0].getAttribute('type')
          post.title = el.getElementsByTagName('title')[0].innerHTML
          post.link = el.getElementsByTagName('link')[0].innerHTML
          dispatch('addGalleryPost', post)
        })
      })
  },
  async addGalleryPost({commit, getters}, val) {
    let ifAlreadyExists = getters.getIfAlreadyInGallery(val.link)
    if(!ifAlreadyExists) commit('addGalleryPost', val)
  },
  async removeGalleryPost({commit, getters}, val) {
    let postsWithoutError = getters.getPostsWithoutItem(val)
    commit('setGalleryPosts', postsWithoutError)
  },
  async openLightboxPost({commit},val) {
    commit('setCurrentLightboxPost', val)
  },
  async closeLightbox({commit}) {
    commit('setCurrentLightboxPost', '')
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
